
import { computed, onMounted, onUpdated, ref } from "vue";
import { useI18n } from "vue-i18n";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default {
  name: "TransportMethod",
  components: {
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const header = ref([
      { column: t("icode"), key: "code", size: "extra_small" },
      { column: t("iname"), key: "name", size: "extra_large" },
    ]);
    const showDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const isLoading = ref(true);
    const search = ref("");
    const tableButtons = ref([]);
    const dataList = ref([]);
    const dataObject = ref([] as any);
    const dialogVisible = ref(false);
    const itemID = ref("");
    const modalMode = ref("add");
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };

    const createElement = () => {
      dialogVisible.value = true;
      itemID.value = "";
      modalMode.value = "add";
    };

    const getItems = () => {
      store.commit("setLoadingStatus", true);
      ApiService.get("/api/messaging/transport_method").then(({ data }) => {
        isLoading.value = false;
        const items: Record<string, any> = [];
        dataList.value = data;
        tableButtons.value = [
          {
            name: "Editar" as never,
            type: "EDIT" as never,
            color: "primary" as never,
          } as never,
        ];
        data.forEach((element) => {
          const td = {
            id: element.id,
            name: element.name,
            code: element.code,
            buttons: [{ type: "EDIT", id: element.id }],
          };
          items.push(td);
        });
        dataObject.value = items;
        store.commit("setLoadingStatus", false);
      });
    };

    const resetModal = () => {
      dialogVisible.value = false;
      getItems();
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      setCurrentPageBreadcrumbs("imessaging_groupage_type", [
        { route: "/messaging", label: "imessaging" },
      ]);
      getItems();
    });

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      store.commit("setLoading", false);
      ApiService.delete(`/api/transport_method/${deleteId.value}?force_delete=true`).then(
        ({ data }) => {
          store.commit("setDeleteId", undefined);
          showDelete.value = false;
          getItems();
        }
      );
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 == "EDIT") {
        dialogVisible.value = true;
        itemID.value = param1;
        modalMode.value = "edit";
      }
      if (param2 == "REMOVE") {
        deleteDialog(param1);
      }
    };

    const onSearch = () => {
      dataObject.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    return {
      user,
      dataList,
      dataObject,
      dialogVisible,
      itemID,
      modalMode,
      header,
      tableButtons,
      isLoading,
      search,
      showDelete,
      deleteDialog,
      deleteElement,
      onSearch,
      actionsButton,
      createElement,
      resetModal,
    };
  },
};
